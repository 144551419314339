<ng-container *ngIf="apartment$ | async as apartment">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <T str="Unit information"></T>
      </mat-card-title>

      <mat-card-subtitle>
        <T str="General information about your unit"></T>
      </mat-card-subtitle>
    </mat-card-header>

    <div class="ams">
      <mat-divider></mat-divider>
    </div>

    <mat-card-content>
      <div class="df gap wrap">
        <apex-title-item>
          <T
            title
            str="Project"></T>

          {{ apartment.Project?.name }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Unit number"></T>

          {{ apartment.name }}
        </apex-title-item>

        <ng-container *ngIf="apartment.Addresses?.length > 0">
          <apex-title-item>
            <T
              title
              str="Address"></T>
            <apex-formatted-address [address]="apartment.Addresses | firstVisitationAddress"></apex-formatted-address>
          </apex-title-item>
        </ng-container>

        <!--<apex-title-item>
          <T
            title
            str="Address"></T>

          {{ apartment.address }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Postal"></T>

          {{ apartment.zip }}
        </apex-title-item>
        -->

        <apex-title-item>
          <T
            title
            str="Handover date"></T>

          {{ handoverDate | dater }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Primary room"></T>

          <span> {{ apartment.primarySpaceSquareMeter }} m<sup>2</sup> </span>
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Unit number"></T>

          {{ apartment.sectionNumber }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Type/Model"></T>

          {{ apartment.model }}
        </apex-title-item>

        <ng-container *ngFor="let trait of apartment.Traits">
          <apex-title-item>
            <ng-container title>{{ trait.name }}</ng-container>

            {{ trait.value }}
          </apex-title-item>
        </ng-container>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <section class="df f1">
        <ng-container *ngIf="apartment.Project?.Company?.name">
          <div class="ams df c f1 asfe">
            <div class="asfe df ac">
              <div class="add-margin-vertical-small aprs">
                <T
                  str="Bought from {ownerName}"
                  [vars]="{ ownerName }"></T>
              </div>
              <div
                class="df asfe"
                *ngIf="companyLogos">
                <apex-logo
                  class="small-logo"
                  [logos]="companyLogos"></apex-logo>
              </div>
            </div>
          </div>
        </ng-container>
      </section>
    </mat-card-actions>
  </mat-card>

  <ng-container *ngIf="!!bannerImages && bannerImages.length">
    <div class="ams amt mat-elevation-z2 ap">
      <h3 class="rmt">
        <T str="Banner images"></T>
      </h3>
      <apex-grid-gallery
        [showPreview]="true"
        [images]="bannerImages"></apex-grid-gallery>
    </div>
  </ng-container>
</ng-container>
