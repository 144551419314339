import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '../../../components/translate/translate.module';

import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { FormattedAddressComponent } from '../../../components/adresses/formatted-address/formatted-address.component';
import { BannerModule } from '../../../components/banner/banner.module';
import { BoxModule } from '../../../components/box/box.module';
import { FilePreviewLoaderModule } from '../../../components/file-preview/file-preview-loader.module';
import { FilePreviewModule } from '../../../components/file-preview/file-preview.module';
import { FileUsageCarouselModule } from '../../../components/file-usage-carousel/file-usage-carousel.module';
import { FileViewerPreviewModule } from '../../../components/file-viewer/preview/file-viewer-preview.module';
import GridGalleryComponent from '../../../components/grid-gallery/grid-gallery.component';
import { ItemModule } from '../../../components/item/item.module';
import { LogoModule } from '../../../components/logo/logo.module';
import { DaterModule } from '../../../pipes/dater/dater.module';
import { FirstVisitationAddressPipe } from '../../../pipes/first-visitation-address/first-visitation-address.pipe';
import { ApartmentInfoComponent } from './apartment-info.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    BoxModule,
    MatTooltipModule,
    ItemModule,
    LogoModule,
    BannerModule,
    MatCardModule,
    FileViewerPreviewModule,
    FilePreviewModule,
    FilePreviewLoaderModule,
    MatRippleModule,
    MatDividerModule,
    FormattedAddressComponent,
    FirstVisitationAddressPipe,
    FileUsageCarouselModule,
    GridGalleryComponent,
    DaterModule,
  ],
  exports: [ApartmentInfoComponent],
  declarations: [ApartmentInfoComponent],
  providers: [],
})
export class ApartmentInfoModule {}
