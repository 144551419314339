<apex-client-addon-breadcrumb
  [cart]="cart"
  [apartment]="apartment">
  <div search>
    <apex-addon-search-button
      class="display-block"
      [CategoriesAndAddons]="CategoriesAndAddons"
      [filteredCategoriesAndAddons]="filteredCategoriesAndAddons"></apex-addon-search-button>
  </div>

  <div
    [ngClass]="{ aml: !addonManager }"
    toc>
    <button
      type="button"
      mat-icon-button
      [matTooltip]="'Table of Contents' | translate"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="tocOpen = !tocOpen">
      <mat-icon>
        {{ tocOpen ? 'close' : 'toc' }}
      </mat-icon>
    </button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="tocOpen"
      (detach)="tocOpen = false">
      <section class="toc-wrapper df c mat-elevation-z2 amtxs scrollable-vertical">
        <ng-container *ngFor="let category of viewAddonCategories; first as first">
          <a
            class="aps link"
            [class.in-view]="categoryInView[category.id]"
            [style.margin-left.px]="category.level * 8"
            routerLink="."
            [fragment]="category.name">
            {{ category.name }}
          </a>
        </ng-container>
      </section>
    </ng-template>
  </div>
</apex-client-addon-breadcrumb>

<ng-container *ngIf="bannerFileUsages$ | async as bannerFileUsages">
  <div
    class="banner-images apt"
    *ngIf="bannerFileUsages?.length">
    <apex-file-usage-preview
      [fileUsages]="bannerFileUsages"
      [navigation]="false"
      [useOldImageLoader]="true">
    </apex-file-usage-preview>
    <div class="image-text df c h-100">
      <h2
        title
        class="rm mat-headline-2">
        <T
          str="Addons for {apartmentName}"
          [vars]="{ apartmentName: apartment?.name }"></T>
      </h2>
      <h3
        subtitle
        class="rm mat-headline-3">
        {{ apartment.Project?.name }}
      </h3>
    </div>
  </div>
</ng-container>

<div
  class="project-text amt apt"
  [innerHTML]="projectText.content"
  *ngIf="projectText.content"></div>
<ng-container *ngIf="!parentAddonCategories?.length">
  <T
    str="No addons available at the moment."
    context="portal:addon"
    _context="portal:addon"></T>
</ng-container>
<div class="df c">
  <apex-client-addon-apartment-tree
    (addonClicked)="goToAddon($event)"
    (add)="add($event)"
    (remove)="remove($event)"
    [level]="0"
    [children]="parentAddonCategories"
    [list]="addonCategories"
    [cart]="cart">
  </apex-client-addon-apartment-tree>
</div>
<div class="amv">
  <button
    mat-raised-button
    (click)="back()">
    <T str="Back"></T>
  </button>
</div>
